.viewmenu{
    background-color: #910061; /* Green */
  border: none;
  color: white;
  padding: 7px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 8px;
}

.krishnagarrestro img{
border-radius: 12px;
}

.textcolor{
  color: #02788a;
}

.homebutton{
  background-color: #09617e; /* Green */
border: none;
color: white;
padding: 7px 20px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 14px;
border-radius: 8px;
}


.whatsappbutton {
  background-color: #076722; /* Green */
  border: none;
  color: #ffffff;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
}

.radiusb img{
  border-radius: 50px;
}

.colorg{
  background-color: #d7edf1;
}