.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  text-decoration: none !important; 
}


.check{
  background-color: #910061; /* Green */
border: none;
color: white;
padding: 7px 32px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
border-radius: 19px;
}


.container {
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.booknow{
  background-color: #910061; /* Green */
  border: none;
  color: white;
  padding: 7px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 8px;
}

.checkmenu{
  background-color: #910061; /* Green */
  border: none;
  color: white;
  padding: 5px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11px;
  border-radius: 5px;
}


.roomkriahnanagar{
  color: #9e1c63;
}

.texts{
  text-align: justify;
  text-justify: inter-word;
}

.colortext h5{
  color:#096c87
}